import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout/Layout';
import { Breakpoints, Container, PageTitleHeader } from '../globalStyles';
import GalleryImage from '../components/GalleryImage/GalleryImage';
import styled from '@emotion/styled';

export const query = graphql`
  query InstagramPosts {
    allInstagramContent {
      nodes {
        id
        localImage {
          childImageSharp {
            gatsbyImageData(
              blurredOptions: {width: 100, toFormat: WEBP}
              formats: WEBP
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  }
`

export const GalleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 50px;
  @media screen and (min-width: ${Breakpoints.Mobile}) {

  }
`

const Gallery = ({ data }) => {
  let pathname = typeof window !== "undefined" ? window.location.pathname : ""
  return (
    <Layout
      title="Gallery of Amazing Cakes"
      description="An image gallery of many of our cakes! Take a look for inspiration!"
      pathname={pathname}
    >
      <Container>
          <PageTitleHeader> Gallery </PageTitleHeader>

          <GalleryContainer>
            {data.allInstagramContent.nodes.map((a) => {
              return (
                <GalleryImage
                  key={a.id}
                  image={a.localImage.childImageSharp.gatsbyImageData} 
                />
            )})}
        </GalleryContainer>
      </Container>
    </Layout>
  )
}

export default Gallery;